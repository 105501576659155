<template>
  <div>   
    <v-container grid-list-xl> 
        <v-layout align-center="" justify-center="">
            <v-col sm="12" cols="12">
                <br>
                <v-card > 
                    <mbs-page-tab 
                        :items="PageTaps"/> 
                    <mbs-item-manager
                        :top_label="false"
                        :name_items="NAME_ITEMS"
                        :name_item="NAME_ITEM"
                        :value_items="VALUE_ITEMS"
                        :value_item="VALUE_ITEM"
                        :field_items="DATA.FIELDS.permissions"
                        :header_items="HEADER_ITEMS"
                        :header_items2="HEADER_ITEMS2"
                        :data_items="DATA_ITEMS" 
                        :path_item="DATA.PATHS.permissions"  
                        :select_items="SELECT_ITEMS" 

                        :table_actions="table_actions" 
                    />
                </v-card>
            </v-col>  
        </v-layout>
    </v-container>  
  </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    let PAGE_NAME = DATA.ITEMS.PERMISSIONS.values
    let ACTION_TIME = 100
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.PERMISSIONS.names,
                NAME_ITEM:DATA.ITEMS.PERMISSIONS.name,
                VALUE_ITEMS:DATA.ITEMS.PERMISSIONS.values,
                VALUE_ITEM:DATA.ITEMS.PERMISSIONS.value,  
                DATA:DATA,
                table_actions:[
                    {type:"btn",action:"add_item",color:"secondary", outlined:false,  text:"Add Permission"},  
                ] 
    
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try { 
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar',  
                })
                this.FEEDBACKS(1000) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: {
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            }, 
            app_links(){
                return this.$router.options.routes
            },
            us(){
                return this.$store.getters.getUser
            },
            ud(){
                return this.$store.getters.getUserDetails
            },
            loading(){  
                return this.MBS.actions.loading
            },
            processes(){  
                return this.MBS.actions.processes
            }, 
            responses(){  
                return this.MBS.actions.responses
            },  
            PageTaps(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("users&permissions",2) 
                return thisLinks
            },

            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"Action type",name:"permission_type",show:true},     
                    {id:0,name:"Access type",name:"access_type",show:true},     
                    {id:0,name:"code",align:"center",show:true},     
                    {id:0,name:"create",value:'created_at',show:false},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        // {name:"View "+this.NAME_CHILD_ITEMS,action:"view_item_child",icon:"mdi-eye"},
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            },
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"code",align:"center",show:true},      
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            },
            HEADER_CHILD_ITEMS(){
                this._HEADER_CHILD_ITEMS = [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"code",align:"center",show:true},   
                    {id:0,name:"region",align:"center",value:'region_name',show:true},   
                    {id:0,name:"region code",align:"center",value:'region_code',show:false},   
                    {id:0,name:"create",value:'created_at',show:false},   
                    {id:0,name:"update",value:'updated_at',show:false},
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Edit "+this.NAME_CHILD_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_CHILD_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
                return this._HEADER_CHILD_ITEMS
            },
            HEADER_CHILD_ITEMS2(){
                this._HEADER_CHILD_ITEMS = [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"code",align:"center",show:true},   
                    {id:0,name:"region",align:"center",value:'region_name',show:true},   
                    {id:0,name:"region code",align:"center",value:'region_code',show:false}, 
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Edit "+this.NAME_CHILD_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_CHILD_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
                return this._HEADER_CHILD_ITEMS
            }, 
            DATA_ITEMS(){
                return this.$store.getters.getPermissions
            },  
            DATA_CHILD_ITEMS(){
                return this.$store.getters.getDistricts
            },  
            LINKS(){
                let links = this.MBS.actions.LINKS  
                return links 
            },  
  
            SELECT_ITEMS(){ 
                let item = [
                    {name:'page'},
                    {name:'action'},
                ]
                let item2 = [
                    {name:'public'},
                    {name:'public user'},
                    {name:'private'},
                    {name:'company'},
                ]
                // let links = this.LINKS
                // console.log(this.app_links,'Pages');
                return {
                    permission_type:item,
                    access_type:item2,
                    app_links:this.app_links,
                }
            },   
        },
        methods: { 
             
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){ 
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            })
                            
                             
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            PAGE_PERMISSION(){
                try {
                    let us
                    if (!us) {
                        this.MBS.actions.go("/")
                    }  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'PAGE_PERMISSION',
                        page:PAGE_NAME, 
                    })
                }
            },
    
        },
        watch: { 
            us(v){
                this.PAGE_PERMISSION()
            },
            loading(value){
                this.FEEDBACKS(1)
            },
            processes(value){
                this.FEEDBACKS(1)
            },
            responses(value){
                this.FEEDBACKS(1) 
            } 
        },
  }
</script>